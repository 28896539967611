export const randomBrandColor = () => {
  const colors = [
    '#ffbfee', // color-brand-pink-2
    '#ffbdcd', // color-brand-rose-2
    '#ffbfc1', // color-brand-red-2
    '#ffdba6', // color-brand-orange-2
    '#ff9', // color-brand-yellow-2
    '#cf3', // color-brand-lime-2
    '#59ffaf', // color-brand-green-2
    '#76ffdd', // color-brand-teal-2
    '#99daff', // color-brand-sky-2
    '#bfcdff', // color-brand-blue-2
    '#d2c9ff', // color-brand-purple-2
  ];

  return colors[Math.floor(Math.random() * colors.length)];
};

export const getRandomPrimaryColorVar = () => {
  const randomNumber = Math.floor(Math.random() * (6 - 0 + 1)) + 0;

  return randomNumber === 0 ? 'color-primary' : `color-primary-${randomNumber}`;
};
